<template>
  <users-edit />
</template>

<script>
import UsersEdit from '@/components/organisms/shared/users/UsersEdit';

export default {
  name: 'ThirdPartyUsersEdit',
  components: { UsersEdit },
};
</script>
